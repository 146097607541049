import { Details, Summary, TroonAccessPlusProduct, TroonAccessProduct } from '@troon/ui';
import { createMemo, For, Match, Show, Switch } from 'solid-js';
import { twJoin } from '@troon/tailwind-preset/merge';
import { IconLogoSquare } from '@troon/icons/logo-square';
import { IconCheck } from '@troon/icons/check';
import { useExperiment } from '@troon/analytics';
import { gql, TroonCardSubscriptionProductType } from '../../../../graphql';
import { getBaseUrl } from '../../../../modules/schema/base-url';
import { createFragment } from '../../../../graphql/create-fragment';
import type { FragmentType } from '../../../../graphql';

type Props = {
	product: FragmentType<typeof ProductInfoFragment>;
	isGift?: boolean;
};

export function ProductInfo(props: Props) {
	const product = createFragment(ProductInfoFragment, props, 'product');
	const pricingBranch = useExperiment('access-pricing');
	const showMonthly = createMemo(() => pricingBranch === 'monthly' && !props.isGift);

	return (
		<div
			class={twJoin(
				'relative overflow-hidden p-6 @container',
				product.type === TroonCardSubscriptionProductType.TroonAccessPlus &&
					'bg-gradient-to-r from-neutral-950 to-brand-700 text-white',
			)}
		>
			<IconLogoSquare class="absolute -right-8 -top-12 z-0 size-56 origin-center -rotate-12 text-brand opacity-20 gradient-mask-b-10" />
			<Show when={props.isGift}>
				<img src={`${getBaseUrl()}/assets/images/gift.svg`} alt="" class="absolute right-0 start-1/2 top-0 z-0 w-1/2" />
			</Show>
			<div class="relative z-0 flex flex-col gap-6 text-base">
				<p>
					<Show
						when={product.type === TroonCardSubscriptionProductType.TroonAccessPlus}
						fallback={<TroonAccessProduct class="h-8" />}
					>
						<TroonAccessPlusProduct class="h-8" current />
					</Show>
					<span class="sr-only">{product.subscriptionName}</span>
				</p>
				<p
					class={twJoin(
						'font-semibold',
						product.subtotalAmount.value !== product.totalAmount.value
							? 'text-3xl @md:text-3xl @lg:text-4xl'
							: 'text-3xl @md:text-4xl @lg:text-5xl',
					)}
				>
					<Show when={product.subtotalAmount.value !== product.totalAmount.value}>
						<span class="me-2 font-medium text-neutral-700 line-through">
							{showMonthly() ? product.monthlySubtotalAmount.displayValue : product.subtotalAmount.displayValue}
						</span>
					</Show>
					<span>{showMonthly() ? product.monthlyTotalAmount.displayValue : product.totalAmount.displayValue}</span>
					<span class="text-base">
						<Switch>
							<Match when={showMonthly()}>/mo</Match>
							<Match when={product.autoRenew && !product.disclosure && !props.isGift}>/yr</Match>
						</Switch>
					</span>
				</p>
				<Show when={showMonthly() || product.disclosure}>
					<p class="flex flex-col gap-1">
						<Show when={showMonthly()}>
							<span class="block pt-2 text-sm font-normal">Billed annually</span>
						</Show>
						<Show when={product.disclosure}>
							{(disclosure) => <span class="text-sm font-normal">{disclosure()}</span>}
						</Show>
					</p>
				</Show>
				<hr
					class={
						product.type === TroonCardSubscriptionProductType.TroonAccessPlus ? 'border-white/20' : 'border-neutral'
					}
				/>
				<Details>
					<Summary>Membership details</Summary>

					<div class="flex flex-col gap-4">
						<ul class="flex flex-col gap-2">
							<For each={product.valueProps}>
								{(value) => (
									<li class="flex flex-row items-center gap-2">
										<IconCheck class="shrink-0 text-xl text-brand" />
										{value}
									</li>
								)}
							</For>
						</ul>
					</div>
				</Details>
			</div>
		</div>
	);
}

const ProductInfoFragment = gql(`fragment AccessProductInfo on TroonCardSubscriptionProduct {
	type
	subscriptionName
	subtotalAmount { displayValue, value }
	monthlySubtotalAmount { displayValue, value }
	totalAmount { displayValue, value, cents }
	monthlyTotalAmount { displayValue, value }
	autoRenew
	disclosure
	valueProps
}`);
